import BaseClient from "../baseClient";

const servicePrefix = "/inceptionService/";

class InceptionService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      AD0000:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      AUTHENTICATION_FAILED: "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      INVALID_VIDEO_UPLOADED:
        "The duration of the uploaded file is invalid. Please upload a different file with correct video duration.",
      ACCESS_DENIED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      BILLING_INFO_MISSING: "Billing details is missing. please add required billing details.",
      APIERR018: "Wallet balance is lower than amount requested",
      UNABLE_TO_CREATE_BOOKING:
        "Impressions not available. kindly adjust your campaign settings accordingly.",
      BOOKING_UNAVAILABLE:
        "Impressions not available. kindly adjust your campaign settings accordingly.",
      HS_BOOKING_INTERNAL_ERROR:
        "Impressions not available. kindly adjust your campaign settings accordingly.",
      SAP_CODE_VALIDATION_FAILED:
        "Business onboarding is currently in progress. Please try again once the process has been completed.",
      NO_ESTIMATION_AVAILABLE:
        "No estimation available for this campaign. please modify your campaign settings.",
      HS_GEC_INVALID_TARGETING_OPTIONS_ERROR: "The given combination is not supported.",
      HS_GEC_INTERNAL_ERROR: "Unable to fetch data",
      BUDGET_LIMIT_EXCEEDS_ERROR:
        "Duplicating this ad set would exceed the total campaign budget limit of __BUDGET__. Please reduce the budget in existing adsets to duplicate this adset. ",
    };
  }

  static getErrorMessageTitle() {
    const defautTitles = super.getErrorMessageTitle();

    return {
      ...defautTitles,
      default: "Internal Error",
      UNABLE_TO_CREATE_BOOKING: "Impressions not available",
      BILLING_INFO_MISSING: "Incomplete billing details",
    };
  }

  static postEntityAction(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}entityAction`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCampignsData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}campaigns`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchMCCCampignsData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}campaigns/user`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postCampignData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}campaigns`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postBulkCampignData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/bulk`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postBulkCampignDataDowload(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/estimate/download`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCampaignPlanSummaryData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/estimate/compute`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchAdSetData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postAdsetData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static duplicateAdsetData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/duplicate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchAd(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postAd(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postAdAdmin(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives/admin`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchEstimates(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/estimate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchTargetingConfig(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/audienceTargeting`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchTargetingData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/targeting`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postTargetingData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/targeting`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchContentSelectionInfo(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}inventories`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchAdSetContentSelectionData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/inventories`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchGecPackages(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/fetchContentPackageData`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postAdSetContentSelectionData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/inventories`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCategoryList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/industries`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchSportsData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/games`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchTournamentsData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/tournaments`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchGstDetails(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/gstDetails`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchLanguages(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/languages`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchAdSetDataAdmin(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/user`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/balance`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static billingCalculationWithAdSet(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/calculator/order`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static billingCalculationWithCampaign(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/calculator/campaign`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static billingPostTransaction(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/transaction`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static billingPostInvoiceTransaction(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/invoice/transaction`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static transferWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/entityTransactionTransfer`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchTournamentList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/tournaments`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchWidgetReport(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/widgets`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchBrandList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/brands`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchWidgetList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/widgetConfiguration`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCampaignList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/campaigns`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchAdFormatList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/adformats`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchBlsQuestionList(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/questions`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static downloadCampaignData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/campaignOrders/download`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postBlsSurvey(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/data/survey`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchOneCampaignData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}campaigns`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static initiateWalletRefund(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/initiateRefund`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static downloadReport(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/reporting/widgets/download`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCustomContentOptions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/customSelection`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchCustomContentFormConfig(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/customSelectionType`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchGecTargetingConfig(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/targetingOptionsType`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static gecTargetingConfigStatus(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/targetingOptions`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postGecTargetingData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/orders/targeting`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCreativeUploadLanguageOptions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/details/fetchMediaUploadLanguageOptions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchCreativeLanguageOptions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/details/fetchCreativeLanguageOptions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static getOrderBudgetDetails(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}midflight/budgetManager`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postMidflightOrderChange(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}midflight/orderChanges`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static getEntityAndBillingData(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/remixHadBill`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static getTdsRefundData(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/tds`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static fetchGECBundlePackageList(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}ht/gec/package`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static postSpotBundleSelectionData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/orders/gec/inventory/setup`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postSpotAdSetDetailsData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/orders/gec/targeting/setup`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static creativeDuplicate(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives/duplicate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postBundleAdSetData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}orders/gec/budget/split`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }
}

export default InceptionService;
